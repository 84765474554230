import { Package, PackageBuyer, PackageInfo } from '@libs/entity-lib';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './package.actions';

export interface State extends EntityState<Package> {
  // additional entities state properties
  loading: boolean;
  selectPackageId: number | null;
  packageInfo: PackageInfo | null;
  packageSelected: Package | null;
  error: any | null;
  packageBuyers: PackageBuyer | null;
}
export function selectPackageId(a: Package): number {
  //In this case this would be optional since primary key is id
  return a.id ?? -1;
}

export const adapter: EntityAdapter<Package> = createEntityAdapter<Package>({
  selectId: selectPackageId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  selectPackageId: null,
  error: null,
  packageInfo: null,
  packageSelected: null,
  packageBuyers: null
});

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchPackages, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchPackagesSuccefully, (state, { packageList }) => {
    return adapter.setAll(packageList, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(fromActions.fetchPackagesFailed, (state, { error }) => {
    return adapter.setAll([], { ...state, loading: false, error });
  }),
  on(fromActions.fetchPackageInfo, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchPackageInfoSuccefully, (state, { packageInfo }) => {
    return {
      ...state,
      packageInfo,
      loading: false,
      error: null,
    };
  }),
  on(fromActions.fetchPackageInfoFailed, (state, { error }) => {
    return { ...state, loading: false, packageInfo: null, error };
  }),
  on(fromActions.postPackage, (state) => ({ ...state, loading: true })),
  on(fromActions.postPackageSuccefully, (state, { packageValue }) =>
    adapter.setOne(packageValue, { ...state, error: null, loading: false, packageSelected: null })
  ),
  on(fromActions.postPackageFailed, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(fromActions.setPackageSelectedById, (state, { packageId }) => {
    return {
      ...state,
      selectPackageId: packageId,
    };
  }),
  on(fromActions.setPackageSelected, (state, { packageSelected }) => {
    return {
      ...state,
      packageSelected
    };
  }),
  on(fromActions.deletePackageSuccessufly, (state, { packageId }) =>
    adapter.removeOne(packageId, { ...state, error: null, loading: false })
  ),
  on(fromActions.deletePackageFailed, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(fromActions.fetchPackageIdBuyers, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchPackageIdBuyersSuccessufly, (state, { packageBuyers }) => ({
    ...state,
    loading: false,
    packageBuyers
  })),
  on(fromActions.fetchPackageIdBuyersFailed, (state, { error }) => {
    return { ...state, packageBuyers: null, loading: false, error };
  }),

  on(fromActions.addPackageToAthlete, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(fromActions.addPackageToAthleteSuccessufly, (state) => {
    return { ...state, loading: true };
  }),
  on(fromActions.addPackageToAthleteFailed, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
);

export const getSelectedPackageId = (state: State) => state.selectPackageId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of packages ids
export const selectCourtIds = selectIds;

// select the dictionary of packages entities
export const selectCourtEntities = selectEntities;

// select the array of packages
export const selectAllPackages = selectAll;

// select the total packages count
export const selectPackageTotal = selectTotal;
